import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
export class Utils {
    public static toFormGroup<T extends object>( item: T ): FormGroup {
        const keys = Object.keys( item )
        const okKeys = [];
        const requiredMap: Map<string, boolean> = new Map<string, boolean>();
        keys.forEach((key) => {
            if( typeof item[key] === "string" || typeof item[key] === "number" || typeof item[key] === "boolean" ) {
                okKeys.push( key );
                requiredMap.set(key, true)
            }
            else if( typeof item[key] === "object" ) {
                if( item[key] === null ) {
                    okKeys.push( key );
                    requiredMap.set(key, false)
                }
            }
        })
        return new FormGroup( Utils.GetFormControls(requiredMap, okKeys, item ));
    }
    private static GetFormControls<T extends object>(map: Map<string, boolean>, array: string[], item: T): { [key: string]: AbstractControl } {
        const ret: { [key: string]: AbstractControl } = {};
        array.forEach((el) => {
            ret[el] = new FormControl(item[el], ( map.get(el) ? [Validators.required] : [] ));
        });
        return ret;
    }
    public static ToInterface<T>( formJson ): T {
        return (formJson as unknown) as T;
    }
    public static Pluck<T>( arr: any[], key: string ): T[] {
        return arr.map( o => o[key]);
    }
    public static GenerateGuid(): string {
        function S4(): string
        {
            return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
        }
        return S4() + S4();
    }

    public static ToMap<T, T1>( array: T1[], keyProp: string ): Map<T, T1[]> {

        let ret: Map<T, T1[]> = new Map<T, T1[]>();

        for( let item of array ) {

            if( !ret.has( item[keyProp] )) {
                ret.set( item[keyProp], [] );
            }

            ret.get( item[keyProp] ).push( item );

        }

        return ret;

    }
}
