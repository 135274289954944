import { Injectable, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { TheBbsWayModule } from 'src/@thebbsway/thebbsway.module';
import { BlockUIModule } from 'ng-block-ui';
import { SocketIoModule } from 'ngx-socket-io';
import { PushNotificationsModule } from 'ng-push-ivy';
import { BrowserModule, Title } from '@angular/platform-browser';
import { EditorModule } from '@tinymce/tinymce-angular';
import { EditClientiModule } from './shared/edit-cliente/edit-cliente.module';
import { BoardWebSocketClient } from './public-pages/order-list/order-list.websocket';
import { EditProdottiModule } from './shared/edit-prodotto/edit-prodotto.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import {BreadcrumbModule} from 'xng-breadcrumb';

import { NgxDropdownMenuSearchModule } from 'ngx-dropdown-menu-search';
import { Subject } from 'rxjs';
import {DeviceDetectorModule} from 'ngx-device-detector';

@Injectable()
export class MatCustomPaginator implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = 'Prima pagina';
  itemsPerPageLabel = 'Elementi per pagina';
  lastPageLabel = 'Ultima pagina';

  nextPageLabel = 'Prossima pagina';
  previousPageLabel = 'Pagina precedente';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Pagina 1 di 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Pagina ${page + 1} di ${amountPages}`;
  }
}


@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatIconModule,


  ],
  declarations: [],
  imports: [
      DeviceDetectorModule.forRoot()
  ]
})
export class MaterialModule { }

@NgModule({
  
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    BlockUIModule.forRoot(),
    FormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      scrollPositionRestoration: 'enabled'
    }),
    HttpClientModule,

    MaterialModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    EditClientiModule,
    EditProdottiModule,
    TheBbsWayModule,

    SocketIoModule,
    PushNotificationsModule,
    EditorModule,
    BreadcrumbModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatIconModule,
    NgxDropdownMenuSearchModule
  ],
  declarations: [
      AppComponent,
      AdminLayoutComponent,
      AuthLayoutComponent
  ],
  providers : [
      Title,
      MatNativeDateModule,
      BoardWebSocketClient,

      { provide: MatPaginatorIntl, useClass: MatCustomPaginator},

  ],
  exports: [
      EditorModule,
      TheBbsWayModule,
      EditClientiModule
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { 
  constructor(matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }
}
