import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient, HttpBackend } from '@angular/common/http';
import { CreateIssueOpenPayload, CreateIssuePayload, CreateIssueResponse, IAssignee, IIssueType, JiraBasicConfiguration } from "./jira.types";
import { Observable } from "rxjs";


@Injectable({
    providedIn: "root"
})
export class JiraInAppReportingService {

    private _isInitialized: boolean = false;
    private _httpClient: HttpClient;

    private _apiBaseUrl: string = '';
    private _jwt: string = '';

    get IsInitialized(): boolean {
        return this._isInitialized;
    }

    @Output() public componentInvoked = new EventEmitter<void>();
    
    constructor( private backend: HttpBackend ) {
        this._httpClient = new HttpClient( backend );
    }

    onBugReporting() {
        this.componentInvoked.emit();
    }

    Init( apiBaseUrl: string ): void {
        this._apiBaseUrl = apiBaseUrl;

        if( !this._apiBaseUrl || this._apiBaseUrl === '' ) {
            console.error( 'No api base url provided!' );
            return;
        }

        this._isInitialized = true;
    }
    
    public static BuildNewIssuePayload( basicConfig: JiraBasicConfiguration, ticketTitle: string, ticketDescription: string, issueType: IIssueType, assignee: IAssignee, username: string ): CreateIssueOpenPayload {
        return {
            fields: {
                assignee: {
                    id: assignee.assigneeId
                },
                issuetype: {
                    id: issueType.id
                },
                project: {
                    key: basicConfig.projectKey
                },
                summary: ticketTitle,
                description: ticketDescription,
                parent: {
                    key: basicConfig.parent ? basicConfig.parent : null
                }
            },
            username: username
        }
    }

    async CreateNewIssue( payload: CreateIssueOpenPayload ): Promise<CreateIssueResponse> {
        if( this.IsInitialized ) {
            return new Promise((resolve, reject) => {
                this._httpClient.post( this._apiBaseUrl + '/jira/create-issue-open', payload)
                    .subscribe(( response: CreateIssueResponse ) => {
                        resolve( response );
                    }, (error) => {
                        reject(error)
                    });
            })
        }

        return null;
        
    }
}