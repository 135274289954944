import { NgModule } from "@angular/core";
import { NullishCoalescePipe } from './NullishCoalesce';

@NgModule({
    declarations: [
        NullishCoalescePipe
    ],
    exports: [
        NullishCoalescePipe
    ]
})
export class CustomPipesModule {}