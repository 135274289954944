import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  private _router: Subscription;
  @BlockUI() private blockUI: NgBlockUI;
  public loading: boolean = false;

  constructor( private router: Router ) {
      router.events.subscribe((event) => {
          if (event instanceof NavigationStart) {
            if( this.blockUI ) {
                this.loading = true;
                this.blockUI.start();
            }
          }
          if (event instanceof NavigationEnd) {
            if( this.blockUI ) {
                this.loading = false;
                this.blockUI.stop();
            }
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            if( this.blockUI ) {
                this.loading = false;
                this.blockUI.stop();
            }
        }
        if (event instanceof NavigationError) {
            if( this.blockUI ) {
                this.loading = false;
                this.blockUI.stop();
            }
        }
      })
  }

    ngOnInit() {
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            const body = document.getElementsByTagName('body')[0];
            const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
            if (body.classList.contains('modal-open')) {
                body.classList.remove('modal-open');
                modalBackdrop.remove();
            }
        });
    }
}
