import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { Utils } from '../../libraries/Utils';

@Component({
    selector: 'bbsway-select-search',
    templateUrl: './select-search.component.html',
    styleUrls: [ './select-search.component.scss' ]
})

export class SelectSearchComponent implements OnInit, OnChanges {

    // Lista di oggetti da cui poter selezionare
    @Input()
    list: any[] = [];

    // Per poter instanziare piu di un componente
    @Input()
    prefix = '';

    // il campo per cui filtrare quando si scrive
    @Input()
    filterField = 'description';

    // il campo chiave del'oggetto preselezionato
    @Input()
    preSelectedKeyField = 'id';

    // istanza del FormControl da monitorare
    @Input()
    itemCtrl: FormControl = new FormControl();

    @Input()
    preSelectedItems: any[] = [];

    @Input()
    formObject: FormGroup = null;

    @Input()
    formObjectField = '';

    @Input()
    placeholderUI = '';

    @Input()
    widthDesktopPerc = '100%';

    @Input()
    multiselection = true;

    @Input()
    complex = false;

    @Input()
    useTemplateString = false;

    @Input()
    templateString = '';

    @Input()
    optionLabelField = 'description';

    @Input()
    hasAddOnFly = false;

    @Input() customCss: any | null = null;

    @Output()
    onAddOnFlyClicked: EventEmitter<any> = new EventEmitter();

    @Output()
    onChipModified: EventEmitter<any> = new EventEmitter();

    @Output()
    clickNotification: EventEmitter<any> = new EventEmitter();

    @Output()
    onInitialized = new EventEmitter<SelectSearchComponent>();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    filteredList: Observable<number[]>;
    _preSelectedArray: any[] = [];

    @ViewChild( 'itemsInput', { static: false }) itemsInput: ElementRef<HTMLInputElement>;
    @ViewChild( 'auto', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger) autoCompleteTrigger: MatAutocompleteTrigger;

    constructor() {
        this.updateList();
    }

    private _filter(value: any): any[] {
        return this.list.filter(item => (item[this.filterField]).toLowerCase().includes(value.toString().toLowerCase()));
    }

    // Per utilizzare questa funzione al posto del filter classico:
    // [complex] = true
    // quando si scrive nell'autocomplete, la stringa digitata viene splittata per ogni spazio.
    // Se in [filterField] viene passata una stringa con dei field divisi da virgola, la funzione
    // controlla ogni campo e per ogni campo controlla che almeno una parola digitata sia contenuta in uno dei campi.
    private _filter_complex( stringa: string ): any[]
    {
        if ( stringa !== '' ) {
            stringa = stringa.toString().trim();
            // scrivo n parole
            let typed_arr = stringa.split(' ');
    
            return this.list.filter( item => {
                let ret = false;
                let filter_fields: string[] = this.filterField.split(',');
    
                for ( let i = 0; i < filter_fields.length; i++ )
                {
                    const filterField = filter_fields[i];
                    if (filterField !== '_TEMPLATE_FIELD_')
                    {
                        for ( let k = 0; k < typed_arr.length; k++ )
                        {
                            const value = typed_arr[k];
                            if ( item[filterField].toLowerCase().includes(value.toString().toLowerCase()))
                            {
                                ret = true;
                                break;
                            }
                        }
                        if ( ret ) {
                            break;
                        }
                    }
                    
                }
    
                return ret;
            });
        }

        return [];
    }

    ngOnInit() {
        // console.log('[SELECT-SEARCH] List: ', this.list )
        // console.log('[SELECT-SEARCH] Pre-Selected items: ', this.preSelectedItems )

        const defaultCss = { 'col-md-11 col-sm-12': this.hasAddOnFly, 'col-md-12': !this.hasAddOnFly }

        if( this.customCss === null ) {
            this.customCss = defaultCss;
        }
        else {
            this.customCss = Object.assign(this.customCss, defaultCss);
        }

        this.onInitialized.emit(this);
        if ( this.useTemplateString )
        {
            if ( !this.list ) {
                this.list = [];
            }

            this.list.forEach(element => {
                this.setOptionLabel( element );
            });
        }
        
        this.setArrayItemsSelected();
    }

    ngOnChanges( changes: any )
    {
        // console.log( this.prefix, 'Select-Search component - Changes: ', changes );
    }

    setOptionLabel( item: any )
    {
        if ( this.useTemplateString && this.templateString !== '' )
        {
            item['_TEMPLATE_FIELD_'] = this.templateString;
            
            const fieldsToUse = this._findAllTemplatedFields( this.templateString );
            
            if ( fieldsToUse.length > 0 )
            {
                for ( let i = 0; i < fieldsToUse.length; i++ )
                {
                    const fieldToUse = fieldsToUse[i];

                    item['_TEMPLATE_FIELD_'] = item['_TEMPLATE_FIELD_'].replace(fieldToUse, item[this._getFieldName(fieldToUse)]);
                }
            }
        }
    }

    _findAllTemplatedFields(stringa: string): string[]
    {
        const ret: string[] = [];

        const regex = /\{(.*?)\}/g;
        const matches = stringa.match(regex);
        for ( let i = 0; i < matches.length; i++ )
        {
            // let field = matches[i].substr(1, matches[i].length - 1);
            const field = matches[i];
            ret.push(field);
        }

        return ret;
    }

    _getFieldName( field: string ): string
    {
        return field.substring( 1, field.length - 1);
    }


    setArrayItemsSelected() {
        this._preSelectedArray = [];

        if ( this.preSelectedItems == null ) {
            this.preSelectedItems = [];
        }

        if ( this.list == null ) {
            this.list = [];
        }

        const preSelectedItemsIds = Utils.Pluck<any>(this.preSelectedItems, 'id');
        // console.log('[SELECT-SEARCH] preSelectedItemsIds: ', preSelectedItemsIds)
        // console.log('[SELECT-SEARCH] preSelectedItems: ', this.preSelectedItems)
        this.list.map(item => {
            if ( preSelectedItemsIds != null && preSelectedItemsIds.indexOf(item[this.preSelectedKeyField]) > -1) {
                // console.log('[SELECT-SEARCH] setArrayItemsSelected -> item: ', item)
                this._preSelectedArray.push(item);
            }
        });
    }

    removeChip(idItem: any){
        console.log('Remove: ', idItem);

        if ( this.multiselection )
        {
            let index = -1;
            for (let i = 0; i < this._preSelectedArray.length; i++ ){
                if (this._preSelectedArray[i].id === idItem ) {
                    index = i;
                    break;
                }
            }
    
            console.log( '[' + this.prefix + '] Index: ', index);
    
            if (index >= 0) {
                this._preSelectedArray.splice(index, 1);
            }
    
            console.log('[' + this.prefix + '] Current items in chip: ', this._preSelectedArray);
        }
        else
        {
            this._preSelectedArray = [];
        }
        
        this.onChipModified.emit(this._preSelectedArray);
        

        // Rimuovo dall'elemento della form.
        /*if( this.formObject !== null )
        {
            const idxForm = this.item.idCategorie.indexOf( idCategoria );
            if( idxForm >= 0 ) {
                this.item.idCategorie.splice(idxForm, 1);
                this.formObject.controls["IDCategorie"].setValue( this.item.idCategorie );
            }
        }*/
        
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        console.log( 'Selected: ', event );
        let newItem = null;
        this.list.map( item => {
            if ( item[this.preSelectedKeyField] === event.option.value ) {
                newItem = item;
            }
        });

        if ( !this.multiselection ) {
            this._preSelectedArray = [];
        }

        this._preSelectedArray.push(newItem);
        this.itemsInput.nativeElement.value = '';
        this.itemCtrl.setValue(null);

        console.log('[' + this.prefix + '] Current items in chip: ', this._preSelectedArray);
        this.onChipModified.emit(this._preSelectedArray);

        // this.item.idCategorie.push(newItem[this.preSelectedKeyField]);
        /*if( this.formObject !== null )
            this.formObject.controls[this.formObjectField].setValue(newItem[this.preSelectedKeyField]);*/
    }

    openAutocomplete(): void {
        this.autoCompleteTrigger.openPanel();
    }
    
    notifyClick(selectedItem){
        this.clickNotification.emit(selectedItem);
    }

    public update() {
        if ( this.list == null ) {
            this.list = [];
        }
        this.ngOnInit();

        console.log('[' + this.prefix + '/Updated] Pre Selected Items: ', this.preSelectedItems);
        console.log('[' + this.prefix + '/Updated] Items: ', this.list);
    }

    addOnFlyClicked()
    {
        this.onAddOnFlyClicked.emit(this);
    }

    updateList( newItems: any[] = [] ) {
        this.list = [...newItems];
        if ( this.useTemplateString )
        {
            if ( !this.list ) {
                this.list = [];
            }

            this.list.forEach(element => {
                this.setOptionLabel( element );
            });
        }
        this.filteredList = this.itemCtrl.valueChanges.pipe(
            startWith(null),
            map((str: string | null) => str ? (this.complex ? this._filter_complex(str) : this._filter(str)) : this.list.slice()));
    }

    clear(): void {
        this._preSelectedArray = [];
    }
}