
export class EnumUtils {
    public static hasFlag(total: number, flag: number): boolean {
        return (total & flag) === flag;
    }

    public static ToEntity( enumObject: any ): EntityBase[] {
        let ret: EntityBase[] = [];
        const keys = Object.keys(enumObject);

        let length = keys.length / 2;

        for( let i = 0; i < length; i++ ) {
            if( Number(keys[i]) !== 0 ) {
                ret.push({
                    ID: Number(keys[i]),
                    Description: enumObject[i]
                })
            }
        }

        return ret;
    }

}

export interface EntityBase {
    ID: number;
    Description: string;
}
