import { NgModule, Optional, SkipSelf } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from '@angular/material/icon';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TheBBsWayHTTPInterceptor } from './interceptors/http.interceptor';
import { ChatSocketClient } from './libraries/ChatSocketClient';
import { SelectSearchModule } from "./common-components/select-search/select-search.module";

@NgModule({
    imports: [
        SelectSearchModule
    ],
    providers: [
        ChatSocketClient,
        {
            provide : HTTP_INTERCEPTORS,
            useClass: TheBBsWayHTTPInterceptor,
            multi   : true
        }
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class TheBbsWayModule {
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry,
        @Optional() @SkipSelf() parentModule?: TheBbsWayModule
    )
    {
        // Do not allow multiple injections
        if ( parentModule )
        {
            throw new Error('TheBbsWayModule has already been loaded. Import this module in the AppModule only.');
        }
    }
    
}