import { Component, OnInit } from "@angular/core";
import PerfectScrollbar from "perfect-scrollbar";

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: "/dashboard",
        title: "Dashboard",
        type: "link",
        icontype: "dashboard"
    },
    {
        path: "/admin",
        title: "Amministrazione",
        type: "sub",
        icontype: "apps",
        collapse: "admin",
        children: [
            { path: "ubicazioni", title: "Ubicazioni", ab: "U" },
            { path: "modalita-consegna", title: "Modalità Consegna", ab: "MC" },
            { path: "modalita-confezionamento", title: "Modalità Confezionamento", ab: "MCF" },
            { path: "devices", title: "Devices", ab: "D" },
            { path: "categorie", title: "Categorie", ab: "CAT" },
            { path: "categorie-app", title: "Categorie App", ab: "CAPP" },
            { path: "impostazioni-negozio", title: "Impostazioni Negozio", ab: "IMP" },
            { path: "reparti", title: "Reparti", ab: "R" },
            { path: "prodotti", title: "Prodotti", ab: "P" },
            { path: "clienti", title: "Clienti", ab: "C" },
            { path: "ordini", title: "Ordini", ab: "O" },
            { path: "template-etichette", title: "Template Etichette", ab: "TE" },
            { path: "stampa-etichette", title: "Stampa Etichette", ab: "SE" },
            { path: "smtp-conf", title: "Configurazione SMTP", ab: "SMTP" },
            { path: "replica-cherry-pick", title: "Replica Cherry Pick", ab: "RCP" },
        ]
    },
];
@Component({
    selector: "app-sidebar-cmp",
    templateUrl: "sidebar.component.html",
    styleUrls: ["sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ps: PerfectScrollbar;

    constructor() { }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>(
                document.querySelector(".sidebar .sidebar-wrapper")
            );
            // this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void {
        console.log('UPDATE PS');
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps?.update();
        }

    }
    isMac(): boolean {
        let bool = false;
        if (
            navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
            navigator.platform.toUpperCase().indexOf("IPAD") >= 0
        ) {
            bool = true;
        }
        return bool;
    }
}
