declare var $: any;
export interface NotificationOptions {
    from: 'top' | 'bottom';
    align: 'left' | 'center' | 'right';
    type: 'info' | 'success' | 'warning' | 'danger' | 'rose' | 'primary',
    message: string;
    timer?: number;
    delay?: number;
}

export class Notifications {
    public static showNotification(options?: NotificationOptions): void {
        ($ as any).notify({
            icon: 'notifications',
            message: options?.message ?? 'Please set a message.'
        }, {
            type: options?.type ?? 'primary',
            timer: options?.timer ?? 3000,
            delay: options?.delay ?? 0,
            placement: {
                from: options?.from ?? 'top',
                align: options?.align ?? 'center'
            },
            template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
          		'<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          		'<i class="material-icons" data-notify="icon">notifications</i> ' +
          		'<span data-notify="title">{1}</span> ' +
          		'<span data-notify="message">{2}</span>' +
          		'<div class="progress" data-notify="progressbar">' +
          			'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          		'</div>' +
          		'<a href="{3}" target="{4}" data-notify="url"></a>' +
          	'</div>'
        });
    }
}
