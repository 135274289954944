import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from '../../environments/environment';

@Injectable()
export class TheBBsWayHTTPInterceptor implements HttpInterceptor {

    BASE_URL = environment.server.url;

    constructor() {}

    intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

        let headers = new HttpHeaders({
            'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0'
        });

        // Clone the request object
        let newReq = req.clone({
            url: this.BASE_URL + req.url,
            headers
        });

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if ( error instanceof HttpErrorResponse && error.status === 401 )
                {

                    // Reload the app
                    location.reload();
                }

                return throwError(error);
            })
        );
    }

}