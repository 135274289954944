export const environment = {
    production: true,
    server: {
        url: `https://casa-del-formaggio.bbsway.dev/api`,
    },
    app: {
        organizationName: `Casa del Formaggio`
    },
    ws: {
        board: {
            url: 'wss://casa-del-formaggio.bbsway.dev',
        }
    },
    jira: {
        projectKey: 'CDF',
        issueTypes: [
            { id: '10011', description: 'Problema' },
            { id: '10001', description: 'Richiesta Funzionalità' },
            // { id: '10002', description: 'Epic' },
            // { id: '10010', description: 'Story' },
            // { id: '10003', description: 'Subtask' }
        ],
        assignees: [
            { assigneeId: '613f1060eaef3400695af828', description: 'Funzionalità' },
            { assigneeId: '5dc2a3ecdfcafc0de6ccbe8a', description: 'Grafica' },
            { assigneeId: '5dc2a3ea7dcf390c618467ed', description: 'SEO' },
            { assigneeId: '5dc2a5807dcf390c61846806', description: 'Altro' }
        ],
        parent: 'CDF-155'
    },
    odinox_apiurl: 'https://odino-x-api.bbsway.dev'
};
