import { AfterViewInit, Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Notifications } from 'src/@thebbsway/libraries/Notifications';
import { Modals } from 'src/@thebbsway/libraries/Modals';
import { Inputs } from '../../../@thebbsway/libraries/Inputs';
import { ICustomer } from '../../amministrazione/clienti/clienti.types';
import { IProduct } from 'src/app/amministrazione/prodotti/prodotti.types';
import { IAlberoCategorieItem, ICategory } from 'src/app/amministrazione/categorie/categorie.types';
import { IDepartment } from 'src/app/amministrazione/reparti/reparti.types';
import { ProdottiService } from 'src/app/amministrazione/prodotti/prodotti.service';
import { ComponentBase } from "src/@thebbsway/component-classes/component-base";


@Component({
  selector: 'app-edit-prodotto',
  templateUrl: './edit-prodotto.component.html',
  styleUrls:['edit-prodotto.component.scss']
})
export class EditProdottoComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
    form: FormGroup;
    @Input() categorie: ICategory[]; 
    @Input() reparti: IDepartment[];
    @Input() simboli: Map<string, IAlberoCategorieItem[]>;

    modalsInstance = Modals;

    @Input() item: ICustomer | null;
    @Input() modalId: string;
    @Input() isCustom: boolean = false;
    @Output() onFormSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() onInit: EventEmitter<EditProdottoComponent> = new EventEmitter<EditProdottoComponent>();

    //@ViewChild('myModal') modal: Bootstrap.Modal;

    public categorieFlat: ICategory[] = [];

    constructor(private _service: ProdottiService) {
            super();
            this.setupForm(null);
    }

    ngOnInit(): void {
        this.onInit.emit(this);
        this._initCategorieFlat(this.categorie);
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit(): void {
        Inputs.focusOnFirstValidInput('input.listenEnter')
    }

    public setupForm( item: IProduct | null ): void {
        this.form = new FormGroup({
            ID: new FormControl( item?.ID ?? 0, [Validators.required]),
            Name: new FormControl( item?.Name ?? '', [Validators.required]),
            CategoryID: new FormControl( item?.Category?.ID ?? 0, [Validators.required]),
            DepartmentID: new FormControl( item?.Department?.ID ?? 0, [Validators.required]),
            IsCustom: new FormControl( this.isCustom, [Validators.required] )
        })
    }

    async save(): Promise<void> {
        
        const product = this.form.getRawValue();
        
        if( this.form.invalid || product.categoriaId == 0 || product.repartoId == 0 || product.nome === '' ) {
            Notifications.showNotification({
                align: 'center',
                from: 'top',
                message: 'Form non valido!',
                type: 'danger',
                timer: 1000,
                delay: 1000
            })
            return;
        }
        this.onFormSave.emit( product );   
    }

    public compareFn(value1: number, value2: number): boolean {
        return value1 === value2;
    }

    public closeModal(): void {
        this.modalsInstance.closeModal(this.modalId);
        console.log('closed');
    }

    private _initCategorieFlat(categorie: ICategory[]): void {
        for (let i = 0; i < categorie.length; ++i) {
            this.categorieFlat.push(categorie[i]);
            if (categorie[i].Children) {
                this._initCategorieFlat(categorie[i].Children);
            }
        }
    }

    public getFirstCharIndex(descrizione: string) {
        for (let i = 0; i < descrizione.length; ++i) {
            if (descrizione[i] !== ' ') {
                return i;
            }
        }
    }
}