import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VERSION } from 'src/environments/version';
import {Modals} from '../../../@thebbsway/libraries/Modals';
import {IAssignee, IIssueType, JiraBasicConfiguration} from '../../../@thebbsway/shared/jira/jira.types';
import {Inputs} from '../../../@thebbsway/libraries/Inputs';
import Swal from 'sweetalert2';
import {BugReportFormPayload} from '../../../@thebbsway/shared/components/bug-report/bug-report.types';
import {JiraInAppReportingService} from '../../../@thebbsway/shared/jira/jira.service';
import {BugReportDialogComponent} from '../../../@thebbsway/shared/components/bug-report/bug-report.component';

@Component({
    selector: 'app-footer-cmp',
    templateUrl: './footer.component.html',
    styleUrls: [ './footer.component.scss' ]
})

export class FooterComponent {

    gitInfo: any = VERSION;
    environment = environment;

    test: Date = new Date();

    constructor(private _jiraService: JiraInAppReportingService) {

    }

    bugReportModalInstance: BugReportDialogComponent;
    onBugReportComponentInit( instance: BugReportDialogComponent ): void {
        this.bugReportModalInstance = instance;
    }

    segnalaBug()
    {
        let dialogText = prompt('Come ti chiami?');
        if( dialogText !== null ) {
            Modals.openModal('report-bug-modal');

            this.bugReportModalInstance.setUsername( dialogText );
            this.bugReportModalInstance.setIssueTypes( environment.jira.issueTypes as IIssueType[] );
            this.bugReportModalInstance.setAssignees( environment.jira.assignees as IAssignee[] );
            this.bugReportModalInstance.setupForm();

            // $('.modal-backdrop').remove();
            Inputs.focusOnFirstValidInput('input.listenEnter');
        }
        else {
            Swal.fire('Non saltare questa domanda!', 'Per poter segnalare i bug o richiedere nuove feature necessitiamo di sapere chi sei!', 'error' );
        }

    }

    async onBugReportCompleted( result: BugReportFormPayload ): Promise<void> {
        // tslint:disable-next-line:max-line-length
        const payload = JiraInAppReportingService.BuildNewIssuePayload( environment.jira as JiraBasicConfiguration, result.Title, result.Description, { id: result.IssueType }, { assigneeId: result.Assignee }, result.Username );

        // Initializing jira with a valid token and an api url.
        this._jiraService.Init( environment.odinox_apiurl );

        try {
            const jiraResult = await this._jiraService.CreateNewIssue( payload );
            if (jiraResult && jiraResult.key && jiraResult.key !== '' ) {
                await Swal.fire( 'SUCCESS', `You successfully created a ticket with ID: "${jiraResult.key}"`, 'success' );
                Modals.closeModal('report-bug-modal');
            } else {
                await Swal.fire('OOOPS', 'We couldn\'t create this ticket, try again later!', 'error' );
            }
        }
        catch ( err ) {
            if ( err.error ) {
                await Swal.fire( err.error.code, err.error.message, 'error' );
            }
        }
    }
}
