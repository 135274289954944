import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { ServiceBase } from "src/@thebbsway/component-classes/service-base";
import { IProduct, GetProdottiResponse } from "./prodotti.types";

@Injectable()
export class ProdottiService extends ServiceBase implements Resolve<any> {

    onRetrievedItems: BehaviorSubject<GetProdottiResponse | null>;

    onRetrievedInitialData: any;

    constructor( private _httpClient: HttpClient ) {
        super();
        this.onRetrievedItems = new BehaviorSubject(null);
    }

    resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.get()
            ]).then(
                ([]) => {
                    resolve();
                },
                reject
            );
        });
    }

    get(): Promise<GetProdottiResponse> {
        this.tryStartBlockUI();
        return new Promise((resolve, reject) => {

            let request: Observable<GetProdottiResponse> = null;
            
            request = this._httpClient.get<GetProdottiResponse>('/products')

            request
                .subscribe((response: GetProdottiResponse) => {
                    this.tryStopBlockUI();
                    this.onRetrievedItems.next( response );
                    resolve( response );
                }, (error) => {
                    this.tryStopBlockUI();
                    reject( error );
                })
        })
    }

    save( item: IProduct ): Promise<IProduct> {
        this.tryStartBlockUI();
        return new Promise((resolve, reject) => {
            this._httpClient.post('/products', item)
                .subscribe((response: IProduct) => {
                    this.tryStopBlockUI();
                    resolve( response );
                }, (error) => {
                    this.tryStopBlockUI();
                    reject( error );
                })
        })
    }

    delete( id: number ): Promise<number> {
        this.tryStartBlockUI('Deleting...');

        return new Promise((resolve, reject) => {
            this._httpClient.delete(`/products/${+id}`)
                .subscribe((response: number) => {
                    this.tryStopBlockUI();
                    resolve( response );
                }, (error) => {
                    this.tryStopBlockUI();
                    reject( error );
                })
        })
    }

    async changeInApp(id: number): Promise<number> {
        this.tryStartBlockUI();

        return new Promise((resolve, reject) => {
            this._httpClient.post(`/products/change-in-app/${id}`, {})
                .subscribe((response: number) => {
                    this.tryStopBlockUI();
                    resolve( response );
                }, (error) => {
                    this.tryStopBlockUI();
                    reject( error );
                })
        })    
    }

    confirmProduct( id: number ): Promise<number> {
        this.tryStartBlockUI();

        return new Promise((resolve, reject) => {
            this._httpClient.post(`/products/confirm-product`, { id })
                .subscribe((response: number) => {
                    this.tryStopBlockUI();
                    resolve( response );
                }, (error) => {
                    this.tryStopBlockUI();
                    reject( error );
                })
        })
    }
}
