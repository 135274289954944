import { NgBlockUI } from "ng-block-ui";

export class ServiceBase {
    blockUI?: NgBlockUI;

    constructor() {

    }

    public setBlockUI( _blockUI: NgBlockUI ): void {
        this.blockUI = _blockUI;
    }

    public tryStartBlockUI(message?: string){
        if( this.blockUI )
            this.blockUI.start(message);
    }

    public tryStopBlockUI()
    {
        if( this.blockUI )
            this.blockUI.stop();
    }
}