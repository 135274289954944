import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ComponentBase } from "src/@thebbsway/component-classes/component-base";
import { Modals } from "src/@thebbsway/libraries/Modals";

@Component({
    selector: 'app-edit-nota',
    templateUrl: './edit-nota.component.html',
    styleUrls: ['edit-nota.component.scss']
})
export class EditNotaComponent extends ComponentBase implements OnInit, OnChanges {
    // private _nota: string;
    @Input() modalId: string;
    @Input() item: string | null;

    modalsInstance = Modals;

    @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() onInit: EventEmitter<EditNotaComponent> = new EventEmitter<EditNotaComponent>();

    @ViewChild('inputNote', { static: false }) textBox: ElementRef;

    public constructor(
    ) {
        super();
    }
    
    ngOnInit(): void {
        this.onInit.emit(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.item = changes.item.currentValue;

        if (this.textBox) {
            this.textBox.nativeElement.value = this.item;
        }
    }

    edit(event: Event) {
        this.item = event.target['value'];
    }

    save() { 
        this.onSave.emit(this.item);
        this.close();
    }

    close(): void {
        this.modalsInstance.closeModal(this.modalId);
    }

}