import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdModule } from '../../md/md.module';

import { CustomPipesModule } from 'src/@thebbsway/pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlagChangerModule } from 'src/@thebbsway/shared/components/flag-changer/flag-changer.module';
import { TagInputModule } from 'ngx-chips';
import { EditClienteComponent } from './edit-cliente.component';
import { SharedMaterialModule } from '../shared-material.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MdModule,
        SharedMaterialModule,
        CustomPipesModule,
        FlagChangerModule,
        TagInputModule
    ],
    providers: [
    ],
    declarations: [EditClienteComponent],
    exports: [ EditClienteComponent ]
})

export class EditClientiModule {}
