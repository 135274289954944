export class Inputs {


    public static listenToKeypress( querySelector: string, fn: any ): void {
        console.log( 'fired binding' );
        document.querySelectorAll(querySelector).forEach((el) => {
            el.addEventListener('keypress', fn, false);
        })
    }

    public static unbindKeypress( querySelector: string, fn: any ): void {
        console.log( 'fired unbinding' );
        document.querySelectorAll(querySelector).forEach((el) => {
            el.removeEventListener('keypress', fn)
        })
    }

    public static focusOnFirstValidInput(querySelector: string): void {
        setTimeout(() => {
            const items = document.querySelectorAll(querySelector);
            // console.log( items )
            if( items && items.length > 0 ) {
                let input = items[0] as HTMLInputElement;
                // console.log( input )
                input.focus();
            }
        }, 500);
    }

}