import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Subject } from "rxjs";
import { ElleLogger } from 'elle-logger';

export class ComponentBase {
    @BlockUI() public blockUI: NgBlockUI;

    public readonly logger = new ElleLogger()

    public _unsubscribeAll: Subject<any>;

    constructor() {
        this._unsubscribeAll = new Subject();
    }
}