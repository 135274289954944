import { EventEmitter, Injectable, Output } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { IOrder, IOrderRow } from "../ordini-shared/ordini.types";

@Injectable()
export class BoardWebSocketClient extends Socket {
    private _SocketId: string; // Descrive l'ID del Client, identificatore unico per il server

    // EVENTI UTILIZZATI DAI COMPONENTI
    @Output() newOrder: EventEmitter<IOrder> = new EventEmitter<IOrder>();
    @Output() editedOrder: EventEmitter<IOrder> = new EventEmitter<IOrder>();
    @Output() deletedOrder: EventEmitter<number> = new EventEmitter<number>();
    @Output() orderCompleted: EventEmitter<IOrder> = new EventEmitter<IOrder>();
    @Output() rowCompleted: EventEmitter<IOrderRow> = new EventEmitter<IOrderRow>();
    @Output() rowUndo: EventEmitter<IOrderRow> = new EventEmitter<IOrderRow>();
    
    // TEST EVENT
    @Output() ping: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor() {
        super({ 
            url: environment.ws.board.url,
            options: { 
                path: '/api/board',
            }
        });

        this.HandleCallbacks();
        this.HandleOrders();
    }

    private HandleCallbacks(): void {

        this.on('after-connection-successful', (payload: IConnection) => {
            this._SocketId = payload.cid.split('#')[1];
        })

        this.on('connect', () => {
            console.log('Board is up!');
        })

        this.on('disconnect', () => {
            console.log('Board is down, trying to reconnect...');
        })

        this.on('error', (error: any) => {
            console.error(`error due to ${(error as Error).message}`);
        });

        this.on("connect_error", (error: any) => {
            console.log(`connect_error due to ${(error as Error).message}`);
        });
        
    }

    /**
     * Function that handles new orders emitted by WebSocket server
     */
    private HandleOrders() {
        this.on('new-order', (order: IOrder) => {
            console.log('new order event', order);
            this.newOrder.emit(order);
        })

        this.on('edited-order', (order: IOrder) => {
            this.editedOrder.emit(order);
        })

        this.on('deleted-data', (id: number) => {
            this.deletedOrder.emit(id);
        });

        this.on('row-completed', (row: IOrderRow) => {
            this.rowCompleted.emit(row);
        });

        this.on('row-undo', (row: IOrderRow) => {
            this.rowUndo.emit(row);
        });

        this.on('order-completed', (order: IOrder) => {
            this.orderCompleted.emit(order);
        })
    }
}

interface IMessage {
    code: string;
    message: string;
    date: string;
}

interface IConnection {
    args: string[];
    cid: string;
}