import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';
import {JiraInAppReportingModule} from '../../../@thebbsway/shared/jira/jira.module';
import {BugReportModule} from '../../../@thebbsway/shared/components/bug-report/bug-report.module';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,


        JiraInAppReportingModule,
        BugReportModule
    ],
    declarations: [ FooterComponent ],
    exports: [ FooterComponent ]
})

export class FooterModule {}
