import { Component, Inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Modals } from "src/@thebbsway/libraries/Modals";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { IAssignee, IIssueType } from '../../jira/jira.types';
import { BugReportFormPayload } from "./bug-report.types";
import {DeviceDetectorService} from 'ngx-device-detector';
import {VERSION} from '../../../../environments/version';

@Component({
    selector: 'app-bug-report',
    templateUrl: './bug-report.component.html',
    styleUrls: [ './bug-report.component.scss' ]
})

export class BugReportDialogComponent implements OnInit {

    form: FormGroup;

    issueTypes: IIssueType[]
    assignees: IAssignee[];

    username: string;

    modalsInstance = Modals;

    @Input() modalId: string;
    @Output() onInit: EventEmitter<BugReportDialogComponent> = new EventEmitter<BugReportDialogComponent>();
    @Output() onBugReportCompleted: EventEmitter<BugReportFormPayload> = new EventEmitter<BugReportFormPayload>();
    
    constructor(private deviceService: DeviceDetectorService,) {

    }

    public setUsername( value: string ): void {
        this.username = value;
    }

    public setIssueTypes( types: IIssueType[] ): void {
        this.issueTypes = types;
    }

    public setAssignees( assignees: IAssignee[] ): void {
        this.assignees = assignees;
    }

    ngOnInit(): void {
        this.onInit.emit( this );
    }

    public setupForm(): void {
        this.form = new FormGroup({
            Title: new FormControl('', [Validators.required, Validators.minLength(3)]),
            Description: new FormControl('', [Validators.required]),
            IssueType: new FormControl('', [Validators.required]),
            Assignee: new FormControl('', [Validators.required]),
            Username: new FormControl(this.username, [Validators.required])
        });
    }

    get descriptionRawControl() {
        return this.form.get("Description") as FormControl;
    }

    inviaForm(): void {
        if ( !this.form.invalid ) {

            let descriptionTech = `\n\n\n**Sistema Operativo:** ${this.deviceService.os} \n**Versione Sistema Operativo:** ${this.deviceService.os_version} \n**Browser:** ${this.deviceService.browser} \n**Versione Browser:** ${this.deviceService.browser_version} \n**Dispositivo:** ${( this.deviceService.isDesktop() ? 'Desktop' : ( this.deviceService.isTablet() ? 'Tablet' : 'Mobile' ) )} \n**Versione Applicazione:** ${VERSION.hash}`;

            this.form.get('Description').setValue( this.form.get('Description').value + descriptionTech);

            this.onBugReportCompleted.emit(this.form.getRawValue() as BugReportFormPayload);
        }
        else {
            Swal.fire('Error', 'Invalid form!', 'error');
        }
    }
}
