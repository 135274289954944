import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Subject } from "rxjs";
import { EntityBase, EnumUtils } from "src/@thebbsway/libraries/EnumUtils";

@Component({
    selector: 'app-flag-changer',
    templateUrl: './flag-changer.component.html',
    styleUrls: ['./flag-changer.component.scss']
})
export class FlagChangerComponent implements OnInit, OnDestroy, AfterViewInit {
    destroy$: Subject<null> = new Subject();

    @Output() onInitialized: EventEmitter<FlagChangerComponent> = new EventEmitter();
    @Output() onNewFlagsCalculated: EventEmitter<number> = new EventEmitter();

    @Input() entityId: number = 0;
    @Input() currentFlags: number = 0;

    @Input() enumObject?: any | null;

    entities: EntityBase[] = [];


    constructor() {

    }

    ngAfterViewInit() {
    }

    ngOnInit() {
        // console.log( Object.keys(this.enumObject));
        this.entities = EnumUtils.ToEntity( this.enumObject );

        this.onInitialized.emit( this );
    }

    private enum

    ngOnDestroy() {
        this.destroy$.next(null);
    }

    async onCheckboxChange( event: MatCheckboxChange ): Promise<void> {
        if( event.checked ) {
            if( !this.hasFlag(this.currentFlags, Number(event.source.value))) {
                this.currentFlags += Number( event.source.value );
            }
        }
        else {
            if( this.hasFlag( this.currentFlags, Number( event.source.value ))) {
                this.currentFlags -= Number( event.source.value );
            }
        }
        this.onNewFlagsCalculated.emit( this.currentFlags );
    }

    hasFlag( total: number, flag: number, log?: boolean ): boolean {
        if( log ) {
            console.log( total, flag, EnumUtils.hasFlag(total, flag) );
        }
        
        return EnumUtils.hasFlag(total, flag);
    }
}
