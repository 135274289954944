import { AfterViewInit, Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Notifications } from 'src/@thebbsway/libraries/Notifications';
import { Modals } from 'src/@thebbsway/libraries/Modals';
import { Inputs } from '../../../@thebbsway/libraries/Inputs';
import { ICustomer } from '../../amministrazione/clienti/clienti.types';

@Component({
  selector: 'app-edit-cliente',
  templateUrl: './edit-cliente.component.html',
  styleUrls:['edit-cliente.component.scss']
})
export class EditClienteComponent implements OnInit, OnDestroy, AfterViewInit {
    form: FormGroup;

    modalsInstance = Modals;

    @Input() item: ICustomer | null;
    @Input() modalId: string;
    @Output() onFormSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() onInit: EventEmitter<EditClienteComponent> = new EventEmitter<EditClienteComponent>();

    //@ViewChild('myModal') modal: Bootstrap.Modal;

    constructor() {
    }

    ngOnInit(): void {
        this.onInit.emit(this);
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit(): void {
        Inputs.focusOnFirstValidInput('input.listenEnter')
    }

    public setupForm( item: ICustomer | null ): void {
        this.form = new FormGroup({
            ID: new FormControl( item?.ID ?? 0, [Validators.required]),
            Name: new FormControl( item?.Name ?? '', [Validators.required]),
            Surname: new FormControl( item?.Surname ?? '', [Validators.required]),
            Phone: new FormControl( item?.Phone ?? ''),
            Address: new FormControl( item?.Address ?? ''),
            Email: new FormControl( item?.Email ?? '', [Validators.email]),
            Active: new FormControl( item?.Active ?? true),
        })
    }

    async save(): Promise<void> {

        if( this.form.invalid ) {
            Notifications.showNotification({
                align: 'center',
                from: 'top',
                message: 'Form non valido!',
                type: 'danger',
                timer: 1000,
                delay: 1000
            })
            return;
        }

        this.onFormSave.emit( this.form.getRawValue());
        
    }

    closeModal(): void {
        this.modalsInstance.closeModal(this.modalId);
    }

}