declare var $: any;
(<any>window).JQuery = $
// import "bootstrap";

export class Modals {
    public static closeModal(modalId: string) {
        $('body').css('overflow', 'auto !important');

        ($(`#${modalId}`) as any).modal('hide');
        $('body').removeClass('modal-open');
        console.log( $('.modal-backdrop'))
        $('.modal-backdrop').remove();

    }

    public static openModal(modalId: string) {
        $('body').css('overflow', 'hidden !important');
        $('body').css('touch-action', 'none !important');
        $('body').css('-webkit-overflow-scrolling', 'none !important');
        $('body').css('overscroll-behavior', 'none !important');

        ($(`#${modalId}`) as any).modal({
            backdrop: 'static',
            keyboard: false,
            show: true,
        });

    }

    public static addStyle(modalId: string){
        ($(`#${modalId}`) as any).css('display', 'flex');
    }
}
