import { Injectable, Output, EventEmitter } from '@angular/core';
import { Socket } from "ngx-socket-io";
import { switchMap } from 'rxjs/operators';
import { of } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable()
export class ChatSocketClient extends Socket {

    set socketId( value: string ) {
        localStorage.setItem('chat-socket-id', value);
    }
    get socketId(): string {
        return localStorage.getItem('chat-socket-id')
    }
    constructor(/*private _userService: UserService*/) {
        super({ url: '' }/*{ 
            url: environment.ws.chat.url + environment.ws.chat.namespace,
            options: { path: environment.ws.chat.path }
        }*/);
        this.on('after-connection-successful', ( res: AfterConnectionSuccessfulResponse ) => {
            this.socketId = res.cid;
            // this._userService.user$.subscribe(( user => {
            //     if( user ) {
            //         this.setMyIdentifier( user );
            //     }
            // }))
        })
    }
    //------------------------------------------------
    // Public Methods by Elle
    //------------------------------------------------
    setMyIdentifier( /*user: User*/ ): void {
        // this.me = user;
        // this.emit('set-my-identifier', { username: user.Username });
    }
}
export interface AfterConnectionSuccessfulResponse {
    args: any[]
    cid: string
}
