import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdModule } from '../../md/md.module';
import { CustomPipesModule } from 'src/@thebbsway/pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlagChangerModule } from 'src/@thebbsway/shared/components/flag-changer/flag-changer.module';
import { TagInputModule } from 'ngx-chips';
import { EditProdottoComponent } from './edit-prodotto.component';
import { EditNotaComponent } from '../edit-nota/edit-nota.component';
import { SharedMaterialModule } from '../shared-material.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MdModule,
        SharedMaterialModule,
        CustomPipesModule,
        FlagChangerModule,
        TagInputModule
    ],
    providers: [
    ],
    declarations: [EditProdottoComponent, EditNotaComponent],
    exports: [ EditProdottoComponent, EditNotaComponent ]
})

export class EditProdottiModule {}
