
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FlagChangerComponent } from './flag-changer.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    declarations: [
        FlagChangerComponent
    ],
    imports: [
        CommonModule,
        
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
    ],
    exports: [FlagChangerComponent],
    providers: [
    ]
})
export class FlagChangerModule {
}