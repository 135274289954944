import { NgModule } from "@angular/core";
import { JiraInAppReportingService } from "./jira.service";

@NgModule({
    declarations: [
    ],
    imports: [
    ],
    providers: [
        JiraInAppReportingService
    ]
})
export class JiraInAppReportingModule {
}